import backgroundVideo from "./videos/Banner/background_video.mp4";
import snapshot from "./images/Banner/snapshot.png";
import summary_image_1x from "./images/Summary/image@1x.png";
import summary_image_2x from "./images/Summary/image@2x.png";
import summary_image_3x from "./images/Summary/image@3x.png";
import details_image_1x from "./images/Details/image@1x.png";
import details_image_2x from "./images/Details/image@2x.png";
import details_image_3x from "./images/Details/image@3x.png";
import gallery_video_1 from "./videos/Gallery/video_1.mp4";
import gallery_video_2 from "./videos/Gallery/video_2.mp4";
import gallery_video_3 from "./videos/Gallery/video_3.mp4";
import gallery_video_4 from "./videos/Gallery/video_4.mp4";
import gallery_video_5 from "./videos/Gallery/video_5.mp4";
import gallery_video_6 from "./videos/Gallery/video_6.mp4";

export const evolutionOfStars = {
  banner: {
    title: "STELLAR SIMULATOR",
    snapshot: snapshot,
    backgroundVideo: backgroundVideo,
    downloadLink:
      "https://store.steampowered.com/app/3036210/Stellar_Simulator/",
    buttonTitle: "COMING SOON",
  },
  summary: {
    text: "This experience is the first chapter in the Simulators series produced by the World Science Festival. Working in teams, your students will learn essential astronomy and physics lessons in a fully immersive environment, sparking more curiosity and engagement than through traditional instruction. How do stars and planets form, what impacts planetary orbits, which stars lead to violent supernovae and the formation of black holes – these and many other questions will be explored by students in a phenomena-based interactive learning environment.",
    image_1x: summary_image_1x,
    image_2x: summary_image_2x,
    image_3x: summary_image_3x,
  },
  details: {
    image_1x: details_image_1x,
    image_2x: details_image_2x,
    image_3x: details_image_3x,
    userEnvironment: "Multiplayer",
    maximumClassSize: "34",
    availableRoles: "Teacher, students",
    pcVrVersion: "Yes",
    standaloneVrVersion: "Yes",
    mobileApp: "Yes",
    webApplication: "/web-applications/visceral-science",
    videoChat: "BlueJeans",
  },
  gallery: {
    video_1: gallery_video_1,
    video_2: gallery_video_2,
    video_3: gallery_video_3,
    video_4: gallery_video_4,
    video_5: gallery_video_5,
    video_6: gallery_video_6,
  },
};
