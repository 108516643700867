import React, { useState } from "react";
import styles from "./styles/Header.module.scss";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { ReactComponent as LogoDesktopImage } from "./assets/images/Logo_desktop.svg";
import { ReactComponent as LogoMobileImage } from "./assets/images/Logo_mobile.svg";
import { ReactComponent as ExternalLinkIcon } from "./assets/images/external_link.svg";
import { ReactComponent as ArrowDownIcon } from "./assets/images/arrow_down.svg";
import WorldScienceFestivalImage from "./assets/images/world-science-festival.png";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import classNames from "classnames";

const ABOUT_US_LINK = {
  title: "About us",
  path: "about",
  disabled: false,
};

const CONTACT_LINK = {
  title: "Contact",
  path: "contact",
  disabled: false,
};

const PRODUCTS_MOBILE_APPLICATIONS = {
  title: "Mobile Applications",
  path: null,
  subRoutes: [
    {
      title: "Stellar Simulator",
      path: "/visceral-science",
      collapse: true,
      subRoutes: [
        {
          title: "iOS",
          path: "https://apps.apple.com/gb/app/stellar-simulator/id6670760982",
          external: true,
        },
        {
          title: "Android",
          path: "https://play.google.com/store/apps/details?id=com.abelanavr.stellarsimulator&hl=en_US&gl=US",
          external: true,
        },
      ],
    },
    {
      title: "Light Speed Simulator",
      path: "/near-the-speed-of-light",
      collapse: true,
      subRoutes: [
        {
          title: "iOS",
          path: "https://apps.apple.com/us/app/near-the-speed-of-light/id6466729484",
          external: true,
        },
        {
          title: "Android",
          path: "https://play.google.com/store/apps/details?id=com.abelanavr.graspingthespeedoflight&hl=en_US&gl=US",
          external: true,
        },
      ],
    },
  ],
};

const PRODUCTS_WEB_APPLICATIONS = {
  title: "Web Applications",
  path: "/web-applications",
  external: true,
  subRoutes: [
    {
      title: "Stellar Simulator",
      path: "/web-applications/visceral-science",
      external: true,
    },
    {
      title: "Light Speed Simulator",
      path: "/web-applications/near-the-speed-of-light",
      external: true,
    },
  ],
};

const PRODUCTS_VR_APPLICATIONS = {
  title: "VR Applications",
  path: null,
  subRoutes: [
    {
      title: "Atom Maker",
      path: "/atom-maker",
    },
    {
      title: "Stellar Simulator",
      path: "/visceral-science",
    },
    {
      title: "Gravity Simulator",
      path: "/gravity-simulator",
    },
    {
      title: "Light Speed Simulator",
      path: "/near-the-speed-of-light",
    },
  ],
};

const PRODUCTS_LINK = {
  title: "Products",
  path: null,
  disabled: false,
  subRoutes: [],
};

const desktopLinks = [
  ABOUT_US_LINK,
  // {
  //   title: "Equipment",
  //   path: "equipment",
  //   disabled: true,
  // },
  // {
  //   title: "VR EDUCATORS",
  //   path: "vr-educators",
  //   disabled: true,
  // },
  CONTACT_LINK,
  {
    ...PRODUCTS_LINK,
    subRoutes: [
      PRODUCTS_VR_APPLICATIONS,
      PRODUCTS_WEB_APPLICATIONS,
      PRODUCTS_MOBILE_APPLICATIONS,
    ],
  },
];

const mobileLinks = [
  ABOUT_US_LINK,
  // {
  //   title: "Equipment",
  //   path: "equipment",
  //   disabled: true,
  // },
  // {
  //   title: "VR EDUCATORS",
  //   path: "vr-educators",
  //   disabled: true,
  // },
  CONTACT_LINK,
  {
    ...PRODUCTS_LINK,
    subRoutes: [
      PRODUCTS_VR_APPLICATIONS,
      PRODUCTS_MOBILE_APPLICATIONS,
      PRODUCTS_WEB_APPLICATIONS,
    ],
  },
];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});

const MenuLink = ({
  className,
  title,
  path,
  disabled,
  external,
  collapse,
  onClick,
}) => {
  if (!path) {
    return (
      <div className={className} onClick={onClick}>
        <div className={styles.menuLinkTitle}>{title}</div>
        {collapse && <ArrowDownIcon className={styles.externalLink} />}
      </div>
    );
  }

  return (
    <NavLink
      className={({ isActive }) =>
        classNames({
          [className]: true,
          [styles.active]: isActive,
          [styles.disabled]: disabled,
        })
      }
      to={path}
      target={external ? "_blank" : "_self"}
      key={path}
    >
      <div className={styles.menuLinkTitle}>{title}</div>
      {external && <ExternalLinkIcon className={styles.externalLink} />}
    </NavLink>
  );
};

const CollapseMenuLink = ({ children, title }) => (
  <Accordion
    className={styles.collapseMenu}
    classes={{
      expanded: styles.collapseMenuExpanded,
    }}
  >
    <AccordionSummary
      expandIcon={<ArrowDownIcon className={styles.collapseMenuArrowDown} />}
      className={styles.collapseMenuSummary}
      classes={{
        content: styles.collapseMenuSummaryContent,
        expandIconWrapper: styles.collapseMenuSummaryExpandIconWrapper,
      }}
    >
      {title}
    </AccordionSummary>
    <AccordionDetails className={styles.collapseMenuContent}>
      {children}
    </AccordionDetails>
  </Accordion>
);

const MenuItem = ({ disabled, path, title, subRoutes }) => {
  const [opened, setOpened] = useState(false);

  let location = useLocation();
  React.useEffect(() => {
    setOpened(false);
  }, [location]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpened(false);
      }}
    >
      <div className={styles.linkContainer}>
        <MenuLink
          className={styles.link}
          title={title}
          path={path}
          disabled={disabled}
          collapse={!!subRoutes}
          onClick={() => setOpened(!opened)}
        />
        {subRoutes && (
          <div
            className={classNames(styles.menu, {
              [styles.opened]: opened,
              [styles.closed]: !opened,
            })}
          >
            <div className={styles.menuPadding}>
              <Grid container spacing={2} className={styles.menuContainer}>
                {subRoutes.map(({ title, path, subRoutes, external }) => (
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      alignItems={{ xs: "center", lg: "flex-start" }}
                      direction="column"
                      wrap="wrap"
                    >
                      <Grid item>
                        <MenuLink
                          className={styles.menuTitle}
                          title={title}
                          path={path}
                          external={external}
                        />
                      </Grid>
                      <Grid item>
                        {subRoutes && (
                          <Grid
                            container
                            spacing={1}
                            direction="column"
                            wrap="nowrap"
                            className={styles.menuContainer}
                          >
                            {subRoutes.map(
                              ({
                                title,
                                path,
                                subRoutes,
                                external,
                                collapse,
                              }) =>
                                collapse ? (
                                  <Grid item className={styles.subRoute}>
                                    <CollapseMenuLink
                                      title={title}
                                      path={path}
                                      external={external}
                                    >
                                      {subRoutes && (
                                        <div
                                          className={styles.subRouteContainer}
                                        >
                                          {subRoutes.map(
                                            ({
                                              title,
                                              path,
                                              collapse,
                                              external,
                                            }) => (
                                              <MenuLink
                                                className={styles.subRouteLink}
                                                title={title}
                                                path={path}
                                                external={external}
                                                collapse={collapse}
                                              />
                                            ),
                                          )}
                                        </div>
                                      )}
                                    </CollapseMenuLink>
                                  </Grid>
                                ) : (
                                  <Grid item className={styles.subRoute}>
                                    <MenuLink
                                      className={styles.subRouteTitle}
                                      title={title}
                                      path={path}
                                      external={external}
                                    />
                                    {subRoutes && (
                                      <div className={styles.subRouteContainer}>
                                        {subRoutes.map(
                                          ({
                                            title,
                                            path,
                                            subRoutes,
                                            collapse,
                                            external,
                                          }) => (
                                            <MenuLink
                                              className={styles.subRouteLink}
                                              title={title}
                                              path={path}
                                              external={external}
                                              collapse={collapse}
                                            />
                                          ),
                                        )}
                                      </div>
                                    )}
                                  </Grid>
                                ),
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const Menu = ({ links }) =>
  links.map(({ disabled, path, title, subRoutes }) => (
    <MenuItem
      disabled={disabled}
      path={path}
      title={title}
      subRoutes={subRoutes}
    />
  ));

const Header = () => {
  const match = useMatch("/web-applications/:gameSlug");
  const [menuOpened, setMenuOpened] = useState(false);

  let location = useLocation();
  React.useEffect(() => {
    setMenuOpened(false);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <ClickAwayListener
        onClickAway={() => {
          setMenuOpened(false);
        }}
      >
        <div
          className={classNames(styles.root, {
            [styles.rootOpened]: menuOpened,
          })}
        >
          <div className={styles.desktopContainer}>
            <div className={styles.container}>
              <div className={styles.links}>
                <NavLink className={styles.logo} to="/">
                  <LogoDesktopImage />
                </NavLink>
                <Menu links={desktopLinks} />
              </div>
              {match ? (
                <a
                  href="https://www.worldsciencefestival.com/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    className={styles.headerImage}
                    src={WorldScienceFestivalImage}
                    alt=""
                  />
                </a>
              ) : (
                <a
                  href="https://app.abelanavr.com/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={styles.loginButton}
                >
                  Account Login
                </a>
              )}
            </div>
          </div>
          <div className={styles.mobileContainer}>
            <div className={styles.container}>
              <NavLink className={styles.logo} to="/">
                <LogoMobileImage />
              </NavLink>
              {match && (
                <a
                  href="https://www.worldsciencefestival.com/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    className={styles.headerImage}
                    src={WorldScienceFestivalImage}
                    alt=""
                  />
                </a>
              )}
              <div
                className={classNames({
                  [styles.menuButton]: true,
                  [styles.open]: menuOpened,
                })}
                onClick={() => setMenuOpened(!menuOpened)}
              >
                <div className={styles.line} />
                <div className={styles.line} />
                <div className={styles.line} />
              </div>
            </div>
            <div className={styles.mobileMenu}>
              <Menu links={mobileLinks} />
              <a
                href="https://app.abelanavr.com/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={styles.loginButton}
              >
                Account Login
              </a>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </ThemeProvider>
  );
};

export default Header;
